import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs/operators';
import { EMPTY, Observable, from, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class FirebaseCrudService {
  private firestore = inject(AngularFirestore);
  private fireauth = inject(AngularFireAuth);

  ensureAuth(): Observable<void> {
    return from(this.fireauth.currentUser).pipe(
      switchMap(user => {
        if (!user) {
          return from(this.fireauth.signInAnonymously()).pipe(
            catchError(error => {
              console.error('FIREBASE AUTH ERROR', error);
              Sentry.captureException(error);
              return throwError(() => error);
            }),
            map(() => undefined)
          );
        }
        return of(undefined);
      })
    );
  }

  getAll<T>(collection: string): Observable<T[]> {
    return this.ensureAuth().pipe(
      switchMap(() =>
        this.firestore.collection(collection).snapshotChanges().pipe(
          map(actions =>
            actions.map(a => ({
              id: a.payload.doc.id,
              ...a.payload.doc.data() as T
            }))
          )
        )
      ),
      catchError(error => {
        this.logError(`FIREBASE GET ALL ERROR >> COLECCTION: ${collection}`, { collection }, error);
        return of([]);
      }),
      shareReplay(1) // Avoid multiple unnecessary calls to Firestore
    );
  }

  getById(collection: string, id: string): Observable<any> {
    return this.ensureAuth().pipe(
      switchMap(() => this.firestore.collection(collection).doc(id).valueChanges()),
      catchError(error => {
        this.logError(`FIREBASE GET BY ID ERROR >> COLLECTION: ${collection} - ID: ${id}`, { collection, id }, error);
        return of(null);
      }),
      shareReplay(1) // Avoid multiple unnecessary calls to Firestore
    );
  }

  getByIdNoValueChange(collection: string, id: string): Observable<any> {

    return this.ensureAuth().pipe(
      switchMap(() => this.firestore
        .collection(collection)
        .doc(id.toString())
        .valueChanges()
        .pipe(first())),
      catchError(error => {
        this.logError(`FIREBASE GET BY (NO VALUE CHANGES) ID ERROR >> COLLECTION: ${collection} - ID: ${id}`, { collection, id }, error);
        return of(null);
      }),
      shareReplay(1) // Avoid multiple unnecessary calls to Firestore
    )

  }

  add(collection: string, docId: string | number, data: any) {
    return this.ensureAuth().pipe(
      switchMap(() => this.firestore.collection(collection).doc(docId.toString()).set(data)),
      catchError(error => {
        this.logError(`FIREBASE ADD ERROR >> COLLECTION: ${collection} - ID: ${docId}`, { collection, docId, data }, error);
        return EMPTY;
      })
    );
  }

  addWithoutDocId(collection: string, data: any): void {
    this.ensureAuth().pipe(
      switchMap(() => this.firestore.collection(collection).add(data)),
      catchError(error => {
        this.logError(`FIREBASE ADD WITHOUT ID ERROR >> COLLECTION: ${collection}`, { collection, data }, error);
        return EMPTY; // Prevents the error from interrupting the execution of the observable
      })
    ).subscribe()
  }

  update(collection: string, id: string | number, data: any) {
    this.ensureAuth().pipe(
      switchMap(() => this.existsDoc(collection, id.toString())),
      filter(exits => exits),
      mergeMap(() => this.firestore.collection(collection).doc(id.toString()).update(data)),
      catchError(error => {
        this.logError(`FIREBASE UPDATE ERROR >> COLLECTION: ${collection} - ID: ${id}`, { collection, id, data }, error);
        return EMPTY; // Prevents the error from interrupting the execution of the observable
      })
    ).subscribe();
  }

  updateSubkeys(payload: FirebaseUpdateSubKeys) {
    const { collection, docId, updateData } = payload;
    return this.ensureAuth().pipe(
      switchMap(() => this.existsDoc(collection, docId)),
      filter(exists => exists),
      mergeMap(() => this.firestore.collection(collection).doc(docId.toString()).update(updateData)),
      catchError(error => {
        this.logError(`FIREBASE UPDATE SUBKEYS ERROR >> COLLECTION: ${collection} - ID: ${docId}`, payload, error);
        return EMPTY; // Prevents the error from interrupting the execution of the observable
      })
    )
  }

  updateSubkey(payload: FirebaseUpdateSubKey): void {
    const { collection, docId, subKeyPath, newValue } = payload;
    this.ensureAuth().pipe(
      switchMap(() => this.existsDoc(collection, docId)),
      filter(exists => exists),
      mergeMap(() => {
        const updateData = { [subKeyPath]: newValue };
        return from(this.firestore.collection(collection).doc(docId.toString()).update(updateData));
      }),
      catchError(error => {
        this.logError(`FIREBASE UPDATE SUBKEY ERROR >> COLLECTION: ${collection} - ID: ${docId} - PATH: ${subKeyPath}`, payload, error);
        return EMPTY; // Prevents the error from interrupting the execution of the observable
      })
    ).subscribe();
  }

  existsDoc(collection: string, docId: string): Observable<any> {
    return this.firestore.collection(collection).doc(docId).get().pipe(
      map(data => data.exists),
      catchError(error => {
        this.logError(`FIREBASE EXISTS ERROR >> COLLECTION: ${collection} - ID: ${docId}`, { collection, docId }, error);
        return of(false);
      })
    )
  }

  delete(collection: string, id: string) {
    this.ensureAuth().pipe(
      switchMap(() => this.firestore.collection(collection).doc(id.toString()).delete()),
      tap({ error: error => this.logError(`FIREBASE DELETE ERROR >> COLLECTION: ${collection} - ID: ${id}`, { collection, id }, error) })
    ).subscribe()
  }

  private logError(message: string, context: any, error: any): void {
    console.error(message, error);
    Sentry.setContext("firebase", { ...context, errorMessage: error.message, errorStack: error.stack });
    Sentry.captureException(new Error(`${message}: ${error.message}`));
  }
}

export interface FirebaseUpdateSubKey {
  collection: string;
  docId: string;
  subKeyPath: string;
  newValue: any;
}

export interface FirebaseUpdateSubKeys {
  collection: string;
  docId: string;
  updateData: any;
}
