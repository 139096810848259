import { Component, Input, Signal, WritableSignal, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentService } from '../modal-content.service';
import { NotificationService } from '../../notification/notification.service';
import { ProfileService } from '../../../settings/account/profile/profile.service';
import { CommonModule } from '@angular/common';
import { tap } from 'rxjs';

@Component({
  selector: 'app-change-credentials',
  standalone: true,
  imports: [FormsModule, CommonModule],
  providers: [ProfileService],
  templateUrl: './change-credentials.component.html',
  styleUrl: './change-credentials.component.scss'
})
export class ChangeCredentialsComponent {

  modalService = inject(ModalContentService);
  activeModal = inject(NgbActiveModal);
  notificationService = inject(NotificationService);
  profileService = inject(ProfileService);

  currentCredential: WritableSignal<string> = signal('');
  newCredential = signal('');
  confirmCredential = signal('');

  credentialType = signal('password');
  inputConfig: any[] = [];

  private _email!: string;

  @Input()
  set email(value: string) {
    const credentialType = !!value ? 'email' : 'password';
    this.credentialType.set(credentialType);
    this.currentCredential.set(value || '');

    this.setUpInputs(value, credentialType);

    this._email = value;
  }

  get email() {
    return this._email;
  }

  togglePasswordVisibility(input: any): void {
    input.hidePassword.update((current: boolean) => !current);
  }

  private setUpInputs(value: string, credentialType: string) {
    this.inputConfig = [
      {
        label: `Current ${credentialType}`,
        ngModel: this.currentCredential,
        disabled: !!value,
        passwordValidations: this.setUpPasswordsValidators(true),
        hidePassword: signal(true),
        isCurrent: true
      },
      {
        label: `New ${credentialType}`,
        ngModel: this.newCredential,
        passwordValidations: this.setUpPasswordsValidators(),
        emailValidations: this.setUpEmailValidations(),
        hidePassword: signal(true),
      },
      {
        label: `Confirm ${credentialType}`,
        ngModel: this.confirmCredential,
        passwordValidations: this.setUpPasswordsValidators(),
        emailValidations: this.setUpEmailValidations(),
        hidePassword: signal(true),
      },
    ]
  }

  private setUpPasswordsValidators(isCurrent: boolean = false) {
    const validations = [
      {
        label: 'At least 8 Characters',
        error: true
      },
      {
        label: 'At least 1 Number (0-9)',
        error: true
      },
      {
        label: 'At least 1 Symbol',
        error: true
      },
      {
        label: 'At least 1 Lowercase (a-z)',
        error: true
      },
      {
        label: 'At least 1 Uppercase (A-Z)',
        error: true
      },
    ]

    if (!isCurrent) {
      validations.push({
        label: 'Do not include spaces',
        error: true,
      })
    }

    return validations;
  }

  private setUpEmailValidations() {
    return [
      {
        label: 'Please enter a valid email address',
        error: true
      }
    ]
  }


  cancelChangesClickHandler() {
    this.activeModal.close(false);
  }

  confirmChangesClickHandler() {
    if (!this.currentCredential())
      return this.notificationService.show({ text: `Please type your current ${this.credentialType()}`, type: 'error' });

    if (!this.newCredential())
      return this.notificationService.show({ text: `Please type a new password ${this.credentialType()}`, type: 'error' });

    if (this.newCredential() !== this.confirmCredential())
      return this.notificationService.show({ text: `New ${this.credentialType()}s do not match`, type: 'error' });

    if (this.email) {
      this.changeEmail();
    } else {
      this.changePassword();
    }
  }

  private changePassword() {
    this.profileService.changePassword(this.currentCredential(), this.newCredential()).subscribe();
  }

  private changeEmail() {
    this.profileService.changeEmail(this.newCredential()).pipe(tap(res => this.activeModal.close({getProfileData: true}))).subscribe();
  }

  validatePassword(event: any, inputConfig: any) {
    if (!event?.target?.value) return
    const value = event.target.value;

    // Min length
    inputConfig.passwordValidations[0].error = value.length < 8;

    // At least one number:
    inputConfig.passwordValidations[1].error = !/\d/.test(value);

    // At least a symbol:
    inputConfig.passwordValidations[2].error = !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);

    // At least one lower case:
    inputConfig.passwordValidations[3].error = !/[a-z]/.test(value);

    // At least one upper case:
    inputConfig.passwordValidations[4].error = !/[A-Z]/.test(value);

    if (!inputConfig.isCurrent) {
      // Do not include spaces:
      inputConfig.passwordValidations[5].error = value.includes(' ');
    }

    inputConfig.isInvalid = inputConfig.passwordValidations.map((e: any) => e.error).some((e: boolean) => e);
  }

  validateEmail(event: any, inputConfig: any): void {
    if (!event?.target?.value) return
    const value = event.target.value;
    // Regular expression to validate a basic email format
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    inputConfig.emailValidations[0].error = !emailRegex.test(value);
    inputConfig.isInvalid = inputConfig.emailValidations.map((e: any) => e.error).some((e: boolean) => e);
  }

}
