import { Injectable } from "@angular/core";
import { Fee, OrderResponse } from "./types/order.type";

@Injectable({
  providedIn: 'root'
})
export class CalculationsService {

  getOrderSubTotal(odooOrder: any, firebaseOrder: any) {
    const products = this.setUpOrderProducts(odooOrder, firebaseOrder);

    const allProducts = [
      ...products?.common ?? [],
      ...products?.subscription ?? []
    ]

    if (!allProducts.length)
      return 0.00
    // Calculate the subtotal of the unsaved products in the current order
    const subtotalFromCurrentOrder = allProducts.reduce((total: number, product: any) => {
      return total + this.calculateProductTotalPrice(product);
    }, 0);
    return +subtotalFromCurrentOrder;
  }

  getOrderGrossSubTotal(odooOrder: any, firebaseOrder: any, couponsOriginalPrice: number) {
    const products = this.setUpOrderProducts(odooOrder, firebaseOrder);

    const allProducts = [
      ...products?.common ?? [],
      ...products?.subscription ?? []
    ]

    if (!allProducts.length)
      return 0.00
    // Calculate the subtotal of the unsaved products in the current order
    const grossSubtotalFromCurrentOrder = allProducts.reduce((total: number, product: any) => {
      return total + this.#calculateProductGrossTotalPrice(product);
    }, 0);

    const couponAmount = this.#checkPendingChanges(products) ? 0 : couponsOriginalPrice || 0
    const grossSubTotal = grossSubtotalFromCurrentOrder + couponAmount
    return grossSubTotal > 0 ? grossSubTotal : 0;
  }

  getOriginalPriceSubtotal(odooOrder: any, firebaseOrder: any) {
    const products = this.setUpOrderProducts(odooOrder, firebaseOrder);

    const allProducts = [
      ...products?.common ?? [],
      ...products?.subscription ?? []
    ]

    if (!allProducts.length)
      return 0.00
    // Calculate the subtotal of the unsaved products in the current order
    const grossSubtotalFromCurrentOrder = allProducts.reduce((total: number, product: any) => {
      return total + this.#calculateProductGrossTotalPrice(product);
    }, 0);

    return grossSubtotalFromCurrentOrder
  }

  getOrderCreditsBalance(data: { creditsAmount: number, subTotal: number, deliveryFee: number, taxes: number, tipAmount: number, donationAmountVoluntary: number }) {
    const { creditsAmount, subTotal, deliveryFee, taxes, tipAmount, donationAmountVoluntary } = data;
    if (!creditsAmount)
      return 0

    const prevTotalAmount = subTotal + deliveryFee + taxes + tipAmount + donationAmountVoluntary;
    const totalAmountWithoutCredits = prevTotalAmount + creditsAmount;
    const balance = (creditsAmount - totalAmountWithoutCredits) + creditsAmount;

    return (balance > 0) ? balance : 0
  }

  getOrderDeliveryFee(odooOrder: any, subTotal: number, products: any): number {
    // TODO If the client doesnt have a order created, with this logic , it will show FREE, and after the SUBMIT ORDER, then we have the specific amount.
    if (!odooOrder?.paymentDetails)
      return 0

    if (!products?.common?.length && !products?.subscription?.length) return 0;

    const { amount, minSpend } = odooOrder?.paymentDetails?.deliveryFee

    if (subTotal >= minSpend)
      return 0

    return +amount
  }

  getOrderTaxes(products: any, odooOrder: any, deliveryFee: number) {
    if (!products)
      return 0

    const allProducts = [...products.common, ...products.subscription]
    const totalTaxes = allProducts.reduce((total: number, product: any) => {
      let bundleTotalPriceWithoutTax = 0;
      let bundleTotalTax = 0;

      if (product?.bundle?.items?.length && !product.bundle.isFixed) {
        product.bundle.items.forEach((bundleItem: any) => {
          const itemPrice = (+((+bundleItem.price || 0).toFixed(2)) * +bundleItem.quantity);
          const itemTax = !bundleItem?.taxes ? 0 : (bundleItem.taxes?.reduce((total: number, item: any) =>
            total + +(item?.percentage)
            , 0) / 100)

          bundleTotalPriceWithoutTax += itemPrice;
          bundleTotalTax += +((itemPrice * (itemTax)).toFixed(2));
        });
      }

      let productTaxPercent = 0

      if (!product?.bundle?.items?.length || product.bundle.isFixed) {
        productTaxPercent = product.taxes?.reduce((total: number, item: any) =>
          total + +(item?.percentage)
          , 0) || 0
      }

      const productPrice = bundleTotalPriceWithoutTax
        ? bundleTotalPriceWithoutTax * +product.quantity
        : product.price
      const productTotalPrice = +productPrice * +product.quantity
      const productTaxAmount = (+productTaxPercent * +productTotalPrice / 100)
      const finalPrice = +(total + productTaxAmount + (bundleTotalTax * +product.quantity)).toFixed(2);

      return finalPrice;
    }, 0);

    // Add delivery fee taxes:
    const deliveryFeeTaxes = deliveryFee === 0 ? 0 : (odooOrder?.paymentDetails?.deliveryFee?.taxes ?? 0);

    return (totalTaxes + deliveryFeeTaxes);
  }

  getOrderTotal(data: { subTotal: number, deliveryFee: number, taxes: number, tipAmount: number, donationAmountVoluntary: number, creditsAmount: number, coupons: number, includeCoupons: boolean, seasonalDeposits: number, additionalFees: number; }): number {
    const { subTotal, deliveryFee, taxes, tipAmount, donationAmountVoluntary, creditsAmount, coupons, includeCoupons, seasonalDeposits, additionalFees } = data;
    const prevTotalAmount = subTotal + deliveryFee + taxes + tipAmount + donationAmountVoluntary
    const couponAmount = includeCoupons ? coupons : 0;
    const prevSubtotal = prevTotalAmount - couponAmount + seasonalDeposits + additionalFees
    const totalAmount = prevSubtotal - creditsAmount

    return totalAmount >= 0 ? totalAmount : 0
  }

  calculateProductTotalPrice(product: any): number {
    let totalPrice = 0;
    if (product?.bundle?.items) {
      if (product.bundle.isFixed) {
        totalPrice = +((+product.price || 0).toFixed(2));
        const premiumItems = product?.bundle?.items?.filter((pi: any) => pi.isPremiumAddon);
        if (premiumItems?.length) {
          totalPrice += premiumItems.reduce((total: number, bundleItem: any) => {
            const bundleItemPrice = (+((+bundleItem?.price || 0).toFixed(2)) * +bundleItem?.quantity) || 0
            return total + bundleItemPrice
          }, 0);
        }
      } else if (product?.bundle?.items?.length)
        totalPrice = product.bundle.items.reduce((total: number, bundleItem: any) => {
          const bundleItemPrice = (bundleItem?.isRemoved ? 0 : (+((+bundleItem?.price || 0).toFixed(2)) * +bundleItem?.quantity) || 0)
          return total + bundleItemPrice
        }, 0);
    }
    if (totalPrice === 0)
      totalPrice = +((+product.price || 0).toFixed(2));

    return totalPrice * product.quantity;
  }

  #calculateProductGrossTotalPrice(product: any): number {

    if (product.isBundle && !product?.bundle?.items?.length)
      return 0;

    let totalPrice = 0;

    if (product.bundle.isFixed) {
      totalPrice = +(product.originalPrice || product.price);
      const premiumItems = product?.bundle?.items?.filter((pi: any) => pi.isPremiumAddon);
      if (premiumItems?.length) {
        totalPrice += premiumItems.reduce((total: number, bundleItem: any) => {
          const bundleItemPrice = (+(bundleItem?.originalPrice || bundleItem.price) * +bundleItem?.quantity) || 0
          return total + bundleItemPrice
        }, 0);
      }
    } else
      totalPrice = product.bundle.items.reduce((total: number, bundleItem: any) => {
        const bundleItemPrice = (bundleItem?.isRemoved ? 0 : (+(bundleItem?.originalPrice || bundleItem.price) * +bundleItem?.quantity) || 0)
        return total + bundleItemPrice
      }, 0);

    if (!totalPrice && !product.isBundle)
      totalPrice = (product.originalPrice || product.price);

    return totalPrice * product.quantity;
  }

  #checkPendingChanges(products: any) {
    return !!(products &&
      [...products?.common, ...products?.subscription].some(product => product.hasPendingChanges));
  }

  setUpOrderProducts(odooOrder: any, firebaseOrder: any): any {
    const { common: odooCommonProducts = [], subscription: odooSubscriptionProducts = [] } = odooOrder?.products || {};
    const { common: fbCommonProducts = [], subscription: fbSubscriptionProducts = [] } = firebaseOrder?.products || {};

    const commonProducts = this.#combineArrays(fbCommonProducts, odooCommonProducts, false)
    const subscriptionProducts = this.#combineArrays(fbSubscriptionProducts, odooSubscriptionProducts, true)

    return {
      common: commonProducts.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0)),
      subscription: subscriptionProducts.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0)),
    }
  }

  getTipAmount(odooOrder: any, firebaseOrder: any, products: any) {
    const odooTipAmount = odooOrder?.paymentDetails?.tip?.amount || 0
    const firebaseTipAmount = firebaseOrder?.paymentDetails?.tip?.amount || 0
    const showTipOrDonation = this.validateProductsExistence(products);
    if (!showTipOrDonation) return 0
    return firebaseTipAmount || odooTipAmount || 0
  }

  getDonationVoluntaryAmount(odooOrder: any, firebaseOrder: any, products: any) {
    const odooDonationAmount = odooOrder?.paymentDetails?.donation?.amount || 0
    const firebaseDonationAmount = firebaseOrder?.paymentDetails?.donation?.amount || 0
    const showTipOrDonation = this.validateProductsExistence(products);
    if (!showTipOrDonation) return 0
    return firebaseDonationAmount || odooDonationAmount || 0
  }

  validateProductsExistence(products: any) {
    return products?.subscription?.length || products?.common?.length
  }

  #combineArrays(fbProducts: any[], odooProducts: any[], isSubscription: boolean): any[] {

    fbProducts = JSON.parse(JSON.stringify(fbProducts));
    odooProducts = JSON.parse(JSON.stringify(odooProducts));

    // Create a map of fbProducts based on ID for efficient lookup
    const fbProductsMap = new Map();
    fbProducts.forEach(product => {
      product.isSubscription = isSubscription
      let key = product.variant.id;
      if (product.package?.id) {
        key += `_${product.package.id}`;
      }
      fbProductsMap.set(key.toString(), product);
    });

    // Combine the products
    const combinedProducts: any[] = [];
    odooProducts.forEach(product => {
      product.isSubscription = isSubscription
      const variantId = product.variant.id;
      const packageId = product.package?.id || null;
      const fbMapKey = `${variantId}${packageId ? `_${packageId}` : ''}`;
      product['isInOdooOrder'] = true;
      product['firebaseMapKey'] = fbMapKey;
      if (fbProductsMap.has(fbMapKey)) {
        // If there's a match, take quantity from fbProducts
        const fbProduct = fbProductsMap.get(fbMapKey);
        product.quantity = fbProduct.quantity;
        product.bundle = fbProduct.bundle
        product.totalPrice = fbProduct.totalPrice
        product['hasPendingChanges'] = true;
        product.updatedAt = fbProduct.updatedAt;
        combinedProducts.push(product);
      } else {
        // If there's no match, take information from odooProducts
        product['hasPendingChanges'] = false;
        combinedProducts.push(product);
      }
    });

    // Add elements from fbProducts if includeFromFb is true
    fbProducts.forEach(product => {
      if (!odooProducts.some(odooProduct => product.package?.id && odooProduct.package?.id ? (product.package.id === odooProduct.package.id) : (odooProduct.variant.id === product.variant.id))) {
        product['hasPendingChanges'] = true;
        product['isInOdooOrder'] = false;
        const variantId = product.variant.id;
        const packageId = product.package?.id || null;
        const fbMapKey = `${variantId}${packageId ? `_${packageId}` : ''}`;
        product['firebaseMapKey'] = fbMapKey;
        combinedProducts.push(product);
      }
    });

    return combinedProducts;
  }

  getOrderCoupons(coupons: any[]) {
    try {
      if (!coupons.length) return 0;
      return coupons.reduce((prev: number, current: any) => prev + current.amount, 0);
    } catch (error) {
      console.log('ERROR WITH COUPONS CALCULATION')
      return 0
    }
  }

  getOrderCouponsOriginalPrice(coupons: any[]) {
    try {
      if (!coupons.length) return 0;
      return coupons.reduce((prev: number, current: any) => prev + current.originalPrice, 0);
    } catch (error) {
      console.log('ERROR WITH COUPONS ORIGINAL PRICE CALCULATION')
      return 0
    }
  }

  getOrderTotalWithoutCredits(data: { subTotal: number, deliveryFee: number, taxes: number, tipAmount: number, donationAmountVoluntary: number, coupons: number, includeCoupons: boolean, seasonalDeposits: number }): number {
    const { subTotal, deliveryFee, taxes, tipAmount, donationAmountVoluntary, coupons, includeCoupons, seasonalDeposits } = data;
    const prevTotalAmount = subTotal + deliveryFee + taxes + tipAmount + donationAmountVoluntary
    const couponAmount = includeCoupons ? coupons : 0;
    const totalAmount = prevTotalAmount - couponAmount + seasonalDeposits

    return totalAmount >= 0 ? +(totalAmount.toFixed(2)) : 0
  }

  calculateOrderAdditionalFees(odooOrder: OrderResponse | null, subtotal: number): Fee[] {
    const fees = odooOrder?.paymentDetails.fees || [];
    fees.forEach(element => element.total = element.percentage ? +(subtotal * (element.percentage / 100)).toFixed(2) : +((element.value || 0).toFixed(2)));
    return fees;
  }

  calculateOrderAdditionalFeesTotal(additionalFees: Fee[]): number {
    const fees = additionalFees || [];
    return fees.reduce((a, b) => a + (b.total || 0), 0);
  }

}
