import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { tap } from 'rxjs';
import { ApiService } from '../shared/api.service';
import { RequestHandlerParams } from '../shared/types/api-service.types';
import { ApiResponse } from '../shared/common/types';
import { arrayToMapMultiKey } from '../shared/common/utils';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  private apiService = inject(ApiService);
  private endpoints = {
    stock: `/stock`,
  }

  isLoading: WritableSignal<boolean> = signal(false);
  isLoaded: WritableSignal<boolean> = signal(false);
  stockSignal: WritableSignal<any> = signal(null);
  mapStockSignal: WritableSignal<Map<number | string, any>> = signal(new Map());

  constructor() { }

  getStock(categoryId?: number, productsId?: number[]) {
    if (!categoryId && !productsId?.length) return;
    this.stockSignal.set(null);
    this.mapStockSignal.set(new Map());
    this.isLoading.set(true);

    const query = this.setUpStockParams(categoryId, productsId);
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.stock}${query}`,
      method: 'GET',
      apiV3: true,
      showErrorMessage: false
    }

    this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: any) => {
        if (!response || !response.data)
          throw new Error('Stock Error');
        this.stockSignal.set(response.data);
        this.mapStockSignal.set(arrayToMapMultiKey(['productId', 'attribute.id', 'attribute.value.id'], response.data));
        this.isLoading.set(false);
        this.isLoaded.set(true);
      }),
    ).subscribe();
  };

  private setUpStockParams(categoryId?: number, productsId?: number[]): string {
    const params: string[] = [];

    if (categoryId) params.push(`categoryId=${categoryId}`);
    if (productsId?.length) {
      const productsIdEnconded = encodeURIComponent(productsId.join(';'))
      params.push(`productId=${productsIdEnconded}`);
    }

    return params.length > 0 ? '?' + params.join('&') : '';
  }

  updateStockMapQuantityByKey(key: string, quantityToSubsctract: number) {
    const stock = this.mapStockSignal();
    const variant = stock.get(key);
    if (variant)
      stock.set(key, { ...variant, stock: variant.stock - quantityToSubsctract });

    this.mapStockSignal.update(() => stock);
  }
}
