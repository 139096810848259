<div class="mt-4">
  <h5 class="text-center fw-normal">
    We are thrilled to have you on board! <br />
    Here's a quick reminder of your delivery information
  </h5>
  <section class="mt-4">

    <div class="d-flex bg-reminder mx-auto col-lg-6 col">
      <div class="p-4">
        <h4></h4>
        <i class="text-success-emphasis fas fa-truck fs-3"></i>
        <h4></h4>
      </div>
      <div class="py-4">

        <ul class="info-list">
          <li class="h6-bold">
            Delivery Day: {{ modalContentData.welcomeMessage?.deliveryDay }}
          </li>
          <li class="h6-semibold">
            Edit before {{ modalContentData.welcomeMessage?.cutoffDay }} at {{
            modalContentData.welcomeMessage?.cutoffTime }}
          </li>
          <li class="h6-bold mt-3">
            Order Cut-Off Time:
          </li>
          <li class="h6-semibold">
            ⏰ {{modalContentData.welcomeMessage?.cutoffDay ?? ''}} at
            {{modalContentData.welcomeMessage?.cutoffTime?.toUpperCase() ?? ''}} (for the upcoming week's delivery)
          </li>
          <li class="h6-bold mt-3">
            Customization Window:
          </li>
          <li class="h6-semibold">
            🛠️ Friday at 05:00 P.M. to Sunday at 05:00 P.M.
          </li>
          <li class="h6-semibold fst-italic mt-3">
            (The week prior to your delivery day)
          </li>
        </ul>

      </div>
    </div>

    @if (isLimitedUser()) {
    <div class="text-center mt-4">
      <p>
        Make sure you
        <a [routerLink]="['/settings/account/profile']" (click)="close()">reset your password</a>
        on your profile settings
      </p>
    </div>
    }
  </section>
  <div class="form-row-action mt-4 mb-4">
    <button type="button" class="btn btn-primary" (click)="goTo()">
      {{ isLimitedUser() ? "Customize box" : "Start shopping" }}
    </button>
  </div>
</div>